import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./screens/homepage/home";
import About from "./screens/about/About";
import "./App.css";
import SmartSalon from "./screens/smartsalon";
import PrivacyPolicy from "./screens/privacypolicy";
import ContactUs from "./screens/contactus";
import BookAppointment from "./screens/bookappointment";
import Newfranchise from "./screens/newfranchisepage";
import ReactGA from "react-ga";
import CustomDesign from "./screens/salonDesign";
import Navbar from "./screens/navbar";
import SalonFinders from "./screens/salonfinder/SalonFinders";
import CustomDesignSalons from "./screens/salonDesign/customsalon";
import { Toaster } from "react-hot-toast";
import Gallery from "./screens/gallery";
import NewGallery from "./screens/gallery/NewGallery";
import "animate.css/animate.min.css";
const TRACKING_ID = "G-H8LMPD3V4F"; // Replace with your tracking ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const [loading, setLoading] = useState(true);
  // const spinner = document.getElementById("spinner");

  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // if (spinner) {
  //   setTimeout(() => {
  //     spinner.style.display = "none";
  //     setLoading(false);
  //   }, 1000);
  // }

  return (
    <div className="roboto-regular relative">
    <Toaster/>
    {
    // !loading && (
      <BrowserRouter>
        {window.location.pathname !== "/customSalon" && <Navbar />}
        <ScrollToTop/>
        <div className="xl:mt-[88px]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/monsoon" element={<SmartSalon />} />
          <Route path="/monsoonpro" element={<SmartSalon />} />
          <Route path="/proplus" element={<SmartSalon />} />


          <Route path="/franchise" element={<Newfranchise />} />
          <Route path="/franchise/*" element={<Newfranchise />} />
          <Route path="/franchise-enquiry" element={<Newfranchise />} />
          <Route path="/franchise-enquiry/*" element={<Newfranchise />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/bookAppointment" element={<BookAppointment />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/media" element={<NewGallery />} />
          <Route path="/salonfinder" element={<SalonFinders />} />

          <Route path="/salonfinder/:id" element={<CustomDesign />} />
          <Route path="/CustomDesignSalons" element={<CustomDesignSalons/>}/>
          <Route path="*" element={<Newfranchise/>}/>
        </Routes>
        </div>
        {/* <NewFooter /> */}
        {/* <FloatingButton/> */}
      </BrowserRouter>
    // )
    }
    </div>
  );
}
