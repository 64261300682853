import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./newfranchise.css";
import { Helmet } from "react-helmet";
import { FaCheck } from "react-icons/fa6";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LOCAL_IMAGES from "../utils/localImages";
import MainText from "../../components/mainTextcomponent";
import NewFooter from "../footer";
import { brandsImg, franchiseBestData } from "../utils/dummydata";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { FaLocationArrow, FaRegCheckCircle } from "react-icons/fa";
import { FaPersonBooth } from "react-icons/fa6";
import { FaCity } from "react-icons/fa6";
import { states } from "../../utils/states";
import CustomInput from "../../components/customInputs/CustomInput";
import CustomSelect from "../../components/customInputs/CustomSelect";
import CustomTextArea from "../../components/customInputs/CustomTextArea";
import ScrollAnimation from "react-animate-on-scroll";
import { postApiData } from "../../utils/services";
import toast from "react-hot-toast";

const initialData = {
  firstName: "",
  lastName: "",
  description: "",
  email: "",
  phoneNumber: "",
  salonType: "",
  salonBudget: "",
  state: "",
  city: "",
};

export default function Newfranchise() {
  const GTM_ID = "GTM-T37QZ7DB";
  const salonData = [
    {
      name: "Monsoon",
    },

    {
      name: "Monsoon Pro",
    },
    {
      name: "Smart Salon",
    },
  ];
  const salonBudgetdata = {
    "Smart Salon": [
      {
        name: "15 lac to 30 lac",
      },
      {
        name: "30 lac to 45 lac",
      },
      {
        name: "45 lac  to 55 lac",
      },
    ],

    "Monsoon Pro": [
      {
        name: "30 lac to 45 lac",
      },
      {
        name: "45 lac to 60 lac",
      },
      {
        name: "60 lac  to 80 lac",
      },
    ],

    Monsoon: [
      {
        name: "50 lac to 70 lac",
      },
      {
        name: "70 lac to 90 lac",
      },
      {
        name: "90 lac  to 1.25 cr",
      },
    ],
  };
  const salonFranchiseDiscription = [
    {
      image: <FaLocationArrow className="text-regel-gray   " size={35} />,
      heading: "200+ locations",
      subHeading: "Two unique business solutions: Salon & Products",
    },
    {
      image: <FaPersonBooth className="text-regel-gray   " size={35} />,
      heading: "3000+ Artists & Technicians",
      subHeading: "Globally recognised products and services",
    },
    {
      image: <FaCity className="text-regel-gray   " size={35} />,
      heading: "77+ Cities 05 Union Territories",
      subHeading: "Continued Business Support Operations, HR, Marketing",
    },
    {
      image: <FaRegHourglassHalf className="text-regel-gray " size={35} />,
      heading: "8 millions people served till date ",
      subHeading: "A legacy of trust and quality service",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    salonType: "",

    state: "",
    city: "",
  });
  const [isSubmited, setIsSubmited] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState(initialData);
  const validateForm = () => {
    const Regex = /^[6-9][0-9]{9}$/;

    const emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const errors = {};

    if (!formValues.firstName || formValues.firstName.trim() === "") {
      errors.firstName = "First Name is required";
    }
    if (!formValues.lastName || formValues.lastName.trim() === "") {
      errors.lastName = "Last Name is required";
    }
    if (!formValues.email || !emailRgx.test(formValues?.email)) {
      errors.email = "Email is required";
    }
    if (!formValues.phoneNumber || !Regex.test(formValues?.phoneNumber)) {
      errors.phoneNumber = "Phone number is required";
    }
    if (!formValues.state || formValues.state.trim() === "") {
      errors.state = "State is required";
    }
    if (!formValues.city || formValues.city.trim() === "") {
      errors.city = "City is required";
    }
    if (!formValues.salonType || formValues.salonType.trim() === "") {
      errors.salonType = "Salon Type is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handlePostapiCall = () => {
    // alert("inside sucess");
    setLoading(true);
    postApiData(
      "formFilling",
      formValues,
      (res) => {
        toast.success("form submited successfully ");
        setIsSubmited(true);
        setLoading(false);

        sessionStorage.setItem("isSubmitted", true);
        setFormValues(initialData);
      },
      (err) => {
        setLoading(false);

        toast.error("error submitting response");
      }
    );
    
  };
  const submithandle = () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    handlePostapiCall();
  };

  // Cleanup timeout on component unmount

  const inputFields = [
    {
      name: "firstName",
      label: "First Name",
      value: formValues?.firstName,
      type: "text",
      placeholder: "Enter First Name",
      required: true,
      error: !!formErrors.firstName,
      helperText: formErrors.firstName,
    },
    {
      name: "lastName",
      label: "Last Name",
      value: formValues?.lastName,
      type: "text",
      placeholder: "Enter Last Name",
      required: true,
      error: !!formErrors.lastName,
      helperText: formErrors.lastName,
    },
    {
      name: "email",
      label: "Email",
      value: formValues?.email,
      type: "email",
      placeholder: "Enter Email",
      required: true,
      error: !!formErrors.email,
      helperText: formErrors.email,
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      value: formValues?.phoneNumber,
      type: "tel",
      placeholder: "Enter Phone Number",
      required: true,
      error: !!formErrors.phoneNumber,
      helperText: formErrors.phoneNumber,
    },
  ];

  const selectFields = [
    {
      name: "state",
      label: "Select State*",
      value: formValues?.state,
      options: Object.keys(states)?.map((elm) => ({ name: elm, value: elm })),
      error: !!formErrors.state,
      helperText: formErrors.state,
    },
    {
      name: "city",
      label: "Select City*",
      value: formValues?.city,
      options:
        states[formValues?.state]?.map((elm) => ({ name: elm, value: elm })) ||
        [],
      error: !!formErrors.city,
      helperText: formErrors.city,
    },
    {
      name: "salonType",
      label: "Salon Type*",
      value: formValues?.salonType,
      options: salonData?.map((item) => ({
        name: item.name,
        value: item.name,
      })),
      error: !!formErrors.salonType,
      helperText: formErrors.salonType,
    },
    {
      name: "salonBudget",
      label: "Budget",
      value: formValues?.salonBudget,
      options: salonBudgetdata[formValues?.salonType]?.map((item) => ({
        name: item.name,
        value: item.name,
      })),
      error: !!formErrors.salonBudget,
      helperText: formErrors.salonBudget,
    },
  ];

  const descFields = {
    name: "description",
    label: "Comments or Message",
    value: formValues?.description,
    type: "textarea",
    placeholder: "Enter your message",
    required: true,
    multiline: true,
    rows: 3,
  };
  useEffect(() => {
    const isSubmited = sessionStorage.getItem("isSubmitted");
    if (isSubmited === "true") {
      setIsSubmited(true);
    }
  }, []);
  const videoUrl = "https://d16a6xzchwrohg.cloudfront.net/franchiseVideo.mp4";
  return (
    <>
      <Box>
        <div className="max-w-[100vw] overflow-x-hidden">
          <Helmet>
            <title>
              Join the Fast-Growing Beauty Industry with Smart Salon Franchise
              in India
            </title>
            <meta
              name="description"
              content="Franchise at Smart Salon India and join the booming beauty industry. With low investment costs and comprehensive training, start your journey towards success and unlock your potential as a beauty salon owner today."
            />
            <meta
              name="keywords"
              content="smart salon franchise, smart salon franchise in India, affordable salon franchise in india, top salon franchise opportunities in india, franchise for salon"
            />
            <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T37QZ7DB');
      `}</script>

            {/* Google Tag Manager (noscript) */}
            <noscript>
              {` <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>`}
            </noscript>
          </Helmet>
          {/* <video controls
            style={{ height: '100%', width: '100%' }}>
            <source src={'https://monsoonsalon.com/wp-content/uploads/2021/08/1st-Branch-Ambience-1-1-1.mp4'} type="video/mp4" />
          </video> */}
          {/* <iframe width="100%" className="youtubeVideo" src="https://www.youtube.com/embed/vVxTlq2jMaU?si=YoYr0k3Z3GE39jys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          <div className="flex flex-col items-center justify-center">
            <div className="w-full h-[30vh] md:h-[calc(100vh-72px)] overflow-hidden">
              <div className="relative aspect-w-16 aspect-h-9 h-full">
                <video
                  className="w-full h-full object-cover"
                  src={videoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                  controlsList="nodownload"
                />
              </div>
            </div>
          </div>
          {/* <div className="h-[400px] md:h-[567px]">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/eRIVTe2XSv8"
            title="Monsoon Salon | The fastest growing salon chain in India"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          </div> */}
        </div>
        <div style={{ bgcolor: "white" }}>
          <div className="my-6 md:mt-9 md:mb-16">
            {/* <div className="bookappointment">
            <span className="onlinetext"
            >      Why Salon Franchising</span>
            <div sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
              <img src={LOCAL_IMAGES.lineIcon} alt=''
              />
            </div>

          </div> */}
            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInLeft">
              <MainText textdata={"Why Salon Franchising"} />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce={true}
              delay={500}
              animateIn="animate__fadeInDown"
            >
              <div className="ourbooking">
                <Typography className="bookingtext">
                  Franchising allows people with no background in the Industry
                  to learn and be business owners
                </Typography>
              </div>
            </ScrollAnimation>
          </div>
          <div className="bg-white ">
            <div className="flex px-3 md:px-6 flex-col gap-6 lg:flex-row items-center justify-center">
              {!isSubmited ? (
                <div className="w-full lg:w-1/2">
                  <ScrollAnimation
                    animateOnce={true}
                    delay={600}
                    animateIn="animate__fadeInLeft"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {inputFields.map((input, index) => {
                        const {
                          placeholder,
                          value,
                          name,
                          label,
                          type,
                          required,
                          error,
                          helperText,
                        } = input;
                        return (
                          <CustomInput
                            key={index}
                            type={type}
                            label={label}
                            value={value}
                            placeholder={placeholder}
                            required={required}
                            name={name}
                            error={error} // Red border if error
                            helperText={helperText}
                            onChange={handleChange}
                          />
                        );
                      })}
                      {selectFields.map((input, index) => {
                        const {
                          value,
                          name,
                          label,
                          required,
                          error,
                          helperText,
                          options,
                        } = input;

                        return (
                          <CustomSelect
                            key={index}
                            options={options}
                            label={label}
                            value={value}
                            required={required}
                            name={name}
                            error={error} // Red border if error
                            helperText={helperText}
                            onChange={handleChange}
                          />
                        );
                      })}
                      <CustomTextArea
                        type={descFields.type}
                        label={descFields.label}
                        value={descFields.value}
                        placeholder={descFields.placeholder}
                        name={descFields.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="" style={{ display: "flex" }}>
                      {!loading ? (
                        <button
                          onClick={submithandle}
                          className="inline-flex mt-5 h-[45px] items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap drop-shadow-md bg-neutral-400 border border-transparent rounded-md shadow-sm hover:bg-black  "
                        >
                          Submit
                        </button>
                      ) : (
                        <button className="inline-flex mt-5 h-[45px] items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap drop-shadow-md  border border-transparent rounded-md shadow-sm bg-black  ">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </button>
                      )}
                    </div>
                  </ScrollAnimation>
                </div>
              ) : (
                <div className="flex w-full lg:w-1/2 items-center justify-center">
                  <div className="text-center">
                    <FaRegCheckCircle className="w-[100px] mx-auto h-[100px] text-green-600 " />
                    <span className="text-green-600 font-medium mt-3">
                      Submitted
                    </span>
                  </div>
                </div>
              )}

              {/* <p className="ortextcall">Call us @   <p className="phoneno">   +919919898006</p>  <p className="phoneno">,+918826345211</p> </p> */}

              <div className="w-[80%] mx-auto lg:w-[40%] ">
                <ScrollAnimation
                  animateOnce={true}
                  delay={600}
                  animateIn="animate__fadeInRight"
                >
                  <img
                    src={LOCAL_IMAGES.franchisepoints}
                    alt="salonbooking"
                    className=" pt-[20px] mx-auto sm:h-[450px] lg:h-auto "
                  />
                </ScrollAnimation>
              </div>

              {/* 
         
          */}
            </div>
            <div className="bg-light-gray bg-opacity-75 text-regel-gray flex flex-col items-center py-9 px-3 my-9">
              <div>
                <ScrollAnimation
                  animateOnce={true}
                  animateIn="animate__fadeInDown"
                >
                  <h2 className="text-center text-2xl font-semibold">Facts</h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__fadeInDown"
                >
                  <p className="text-center text-lg mt-3">
                    India’s most stable, growing salon chain
                  </p>
                </ScrollAnimation>
              </div>
              <ScrollAnimation
                animateOnce={true}
                delay={500}
                animateIn="animate__fadeInLeft"
              >
                <div className="flex flex-wrap justify-center items-center ">
                  {salonFranchiseDiscription?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center justify-center mt-12 mx-4"
                    >
                      {item.image}
                      <p
                        className="text-center mt-4 font-extrabold"
                        style={{ fontSize: "20px" }}
                      >
                        {item.heading}
                      </p>

                      <p className="text-center  mt-2 w-[70%] mx-auto  text-[16px] ">
                        {item.subHeading}
                      </p>
                    </div>
                  ))}
                </div>
              </ScrollAnimation>
            </div>
            <div className="my-8 md:my-16 text-center">
              <ScrollAnimation
                animateOnce={true}
                animateIn="animate__fadeInDown"
              >
                <h1 className="font-bold text-xl  px-6  md:text-2xl">
                  Looking For Salon Franchise In India ?
                </h1>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="animate__fadeIn">
                <p className="discriptionText ">
                  Choose Smart Salon is a business that is always changing. We
                  are a company of salons that use technology to run their
                  businesses more efficiently and earn 30% more money. Our
                  ecosystem works with both online and offline systems, which
                  gives us an edge over our competitors. There are more than 115
                  Monsoon Salon franchises and more than 1,000 beauty and makeup
                  experts all over India. It is the fastest-growing salon and
                  the best salon franchise in India. Also on the list of the
                  best salon franchises in India is Monsoon Salon.
                </p>
              </ScrollAnimation>
            </div>
            <div className="">
              {/* <h1 className="bookappointmentsalon">Looking For Salon Franchise In India
            </h1> */}

              {/* <img
        src="https://monsoonsalon.com/wp-content/uploads/2021/01/0Y2A0396-scaled-e1638956901369.jpg"
        alt=""
        className="responsive-image"
      /> */}
              <div className="px-6 mb-8 md:mb-16  text-center">
                <ScrollAnimation
                  animateOnce={true}
                  animateIn="animate__fadeInDown"
                >
                  <h1 className="font-bold text-xl   md:text-2xl">
                    Why is Smart Salon Franchise the Best?
                  </h1>
                </ScrollAnimation>
                <ScrollAnimation
                  animateOnce={true}
                  delay={200}
                  animateIn="animate__fadeIn"
                >
                  {franchiseBestData?.map((item, index) => {
                    return (
                      <div className="my-3 flex items-center gap-2 last:mt-3 last:mb-0">
                        <FaCheck
                          size={20}
                          className="text-green-600 min-w-[25px] "
                        />
                        <p className="text-[16px] text-start text-description">
                          {item.discrption}
                        </p>
                      </div>
                    );
                  })}
                </ScrollAnimation>
              </div>
            </div>

            <div className="my-9 py-9 bg-new-yellow">
              <div className="">
                <div className="w-fit mx-auto">
                  <p className="text-white font-normal text-[1.8rem]">
                    One Move Closer
                  </p>
                  <hr className="border-t-1 my-2 w-[60%] mx-auto border  border-black " />
                </div>
                <div className="my-6 md:my-9 px-3 mx-auto  text-center text-white">
                  <p className="w-full text-xl font-normal mx-auto">
                    To know more, get in touch with us at,Email us at{" "}
                    <a
                      href="mailto:info@theprofessionalworld.com"
                      className="text-regel-gray"
                    >
                      {" "}
                      info@theprofessionalworld.com
                    </a>{" "}
                    or Call us at
                    <a href="tel:+91-7703900678" className="text-regel-gray">
                      +91-7703900678 ,
                    </a>
                    <a href="tel:+91-7703900678" className="text-regel-gray">
                      +91-9315788084
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <MainText textdata={"Partner Brands"} />
            <div
              className="my-6"
              // style={{ marginTop: 10, marginBottom: '10px', height: '100px', backgroundColor: '', justifyContent: 'center', alignItems: 'center' }}
            >
              <Slider {...settings}>
                {brandsImg?.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "green",
                      }}
                    >
                      <img
                        src={item.brandUrl}
                        style={{ height: "60%", width: "50%" }}
                        alt="galleryimages"
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>

        {/* <Footer/> */}
        <NewFooter />
      </Box>
    </>
  );
}
