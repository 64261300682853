import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../loader";
import LOCAL_IMAGES from "../utils/localImages";
import "./smartsalon.css";
import { Helmet } from "react-helmet";
import NewFooter from "../footer";
import { FaCheckCircle } from "react-icons/fa";

export default function SmartSalon() {
  // let {id}=useParams()
  // console.log("ddfjksjkdfsjkfds",id)
  const [salonData, setSalonData] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;
  console.log("datamonsoonsalon", data);

  useEffect(() => {
    axios
      .post("https://smartsalon.in/api/salons", {
        salonType: data,
      })
      .then(function (response) {
        // handle success
        console.log("gettingresponse for salons", response?.data);
        setSalonData(response?.data?.data);
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        setLoader(true);
        console.log(error);
      });
  }, []);
  const onpressFranchise = () => {
    navigate("/franchise");
  };
  const Monsoon = {
    title: "Franchisee Requirements",
    items: [
      "Range for potential investments: 55 lac to 1 cr",
      "Standard Salon Size: Min 1000 Square Feet",
      "A background in the beauty industry is helpful, but it's not required; we'll provide you with all the training you need to succeed.",
      "Alignment with the brand's core principles of excellence, integrity, and customer happiness.",
    ],
  };
  const MonsoonPro = {
    title: "Franchisee Requirements",
    items: [
      "Range for potential investments: 35 lac to 55 lac",
      "Standard Salon Size: Minimum Salon Area - 700sq to 1000sq",
      "A background in the beauty industry is helpful, but it's not required; we'll provide you with all the training you need to succeed.",
      "Alignment with the brand's core principles of excellence, integrity, and customer happiness.",
    ],
  };
  const SmartSalon = {
    title: "Franchisee Requirements",
    items: [
      "Range for potential investments: 15 lac to 35 lac",
      "Standard Salon Size: Minimum Salon Area - 300sq to 700sq",
      "A background in the beauty industry is helpful, but it's not required; we'll provide you with all the training you need to succeed.",
      "Alignment with the brand's core principles of excellence, integrity, and customer happiness.",
    ],
  };
  const contentArray = [
    {
      title: "Franchise Overview",
      items: [
        "Cutting-Edge Techniques & Trendy Products. We offer a full range of services to help our customers always look their best, including haircuts, style, colouring, manicures, pedicures, and facials.",
        "Our salons are supported by a staff of highly trained specialists with years of combined experience, guaranteeing treatments that are second to none and always exceed client expectations.",
        "Each franchise is tailored to fit its specific market by providing services that are tailored to the needs of the local population. This allows for maximum market penetration and customer satisfaction.",
      ],
    },
    {
      title: "Why Choose Us?",
      items: [
        "Our franchise concept was carefully crafted to be easily replicated, guaranteeing a constant representation of our brand while still allowing for local adaptation.",
        "We provide our franchisees with in-depth training, as well as ongoing assistance, to help them run their businesses at optimal efficiency and success.",
        "Our salons are committed to social responsibility, so we only use environmentally friendly products and processes that not only make clients look and feel better, but also keep our planet safe.",
      ],
    },
  ];

  // Conditionally add the required franchise object
  if (data === "Monsoon") {
    contentArray.push(Monsoon);
  } else if (data === "MonsoonPro") {
    contentArray.push(MonsoonPro);
  } else if (data === "SmartSalon") {
    contentArray.push(SmartSalon);
  }
  const fields = {
    Monsoon: {
      iframe: {
        src: "https://www.youtube.com/embed/9uJkaJtx4Ng",
        title:
          "Step into luxury - Monsoon Salon#monsoon #salon #monsoonsalon #haircolor #hair #ytshorts",
      },
      image: LOCAL_IMAGES.monsoonlogo,
      mainText: `Here at Monsoon Salon, we are proud to present a unique
                    franchise opportunity at the intersection of aesthetics,
                    technology, and savvy business practise. Since 2013, our
                    India-based company has been at the forefront of the beauty
                    business, providing clients with unforgettable services that
                    boost their self-esteem and make them feel like a million
                    bucks.`,
    },

    MonsoonPro: {
      iframe: {
        src: "https://www.youtube.com/embed/vVxTlq2jMaU",
        title:
          "MONSOON SALON GORAKHPUR | BEST SALON IN GORAKHPUR | PREMIUM CLASS BEAUTY SERVICES IN GORAKHPUR.",
      },
      image: LOCAL_IMAGES.Monsoonpro,
      mainText: `Welcome to Monsoon Pro Salon, where beauty, innovation, and business acumen converge to offer a remarkable franchising opportunity! Rooted in 58 cities and 4 Union territories, we have been pioneers in the beauty industry since 2013, meticulously crafting unparalleled experiences for our customers, ensuring they step out with confidence and a dash of allure.`,
    },

    SmartSalon: {
      iframe: {
        src: "https://www.youtube.com/embed/4nH8362EcDc",
        title: "Your Beauty, Upgraded: Be U Salon Is Pro Plus Smart Salon",
      },
      image: LOCAL_IMAGES.smartsalonpro,
      mainText: `Welcome to Pro Plus Salon, where beauty meets innovation. Our salon franchise offers an exciting opportunity to empower entrepreneurs to start their own business with optimal utilization of 300-600 sq. ft. of retail space and integration of tech to enhance the customer experience.`,
    },
  };

  return !loader ? (
    <>
      <>
        {data === "Monsoon" && (
          <>
            <Helmet>
              <title>
                Smart Salon is an extensive chain of salons in India
              </title>
              <meta
                name="description"
                content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities."
              />
              <meta
                name="classification"
                content="Salon chain with a wide range of offerings, from hair care to bridal makeup to nail care. Provides salon franchise options entrepreneurs."
              />
              <meta
                property="og:title"
                content="Smart Salon is an extensive chain of salons in India"
              />
              <meta
                property="og:image"
                content="https://smartsalon.in/SmartSalon/monsoon"
              />
              <meta
                property="og:description"
                content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities."
              />
              <meta property="og:locale" content="en_IN" />
              <meta property="og:type" content="article" />
              <meta
                property="og:url"
                content="https://smartsalon.in/SmartSalon/monsoon"
              />
              <meta
                property="og:site_name"
                content="Search Results | Smart Salon Monsoon"
              />
              <link
                rel="canonical"
                href="https://smartsalon.in/SmartSalon/monsoon"
              />
              {/* Combine all robots meta tags into one */}
              <meta
                name="robots"
                content="follow, index, all, noodp, noydir, max-snippet:-1, max-image-preview:large"
              />
              <meta name="revisit-after" content="2 days" />
              <meta name="googlebot" content="index,follow" />
              <meta name="expires" content="never" />
              {/* Use httpEquiv for equivalent HTTP header */}
              <meta httpEquiv="language" content="English" />
              <meta name="document-type" content="public" />
              <meta name="author" content="SmartSalon" />
              <script>{`
   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','GTM-T37QZ7DB');
 `}</script>

        {/* Google Tag Manager (noscript) */}
         <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>`}
        </noscript>
     
            </Helmet>
          </>
        )}
        {data === "MonsoonPro" && (
          <>
            <Helmet>
              <title>
                Smart Salon is an extensive chain of salons in India
              </title>
              <meta
                name="description"
                content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities."
              />
              <meta
                name="classification"
                content="Salon chain with a wide range of offerings, from hair care to bridal makeup to nail care. Provides salon franchise options entrepreneurs."
              />
              <meta
                property="og:title"
                content="Smart Salon is an extensive chain of salons in India"
              />
              <meta
                property="og:image"
                content="https://smartsalon.in/SmartSalon/monsoonpro"
              />
              <meta
                property="og:description"
                content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities."
              />
              <meta property="og:locale" content="en_IN" />
              <meta property="og:type" content="article" />
              <meta
                property="og:url"
                content="https://smartsalon.in/SmartSalon/monsoonpro"
              />
              <meta
                property="og:site_name"
                content="Search Results | Momsoon Pro"
              />
              <link
                rel="canonical"
                href="https://smartsalon.in/SmartSalon/monsoonpro"
              />
              <meta name="robots" content="follow, index, all" />
              <meta name="robots" content="noodp,noydir" />
              <meta
                name="robots"
                content="max-snippet:-1, max-image-preview:large"
              />
              <meta name="revisit-after" content="2 days" />
              <meta name="googlebot" content="index,follow" />
              <meta name="expires" content="never" />
              <meta http-equiv="language" content="English" />
              <meta name="document-type" content="public" />
              <meta name="author" content="SmartSalon" />
              <script>{`
   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','GTM-T37QZ7DB');
 `}</script>

        {/* Google Tag Manager (noscript) */}
         <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>`}
        </noscript>
     
            </Helmet>
          </>
        )}
        {data === "SmartSalon" && (
          <>
            <Helmet>
              <title>
                Smart Salon is an extensive chain of salons in India
              </title>
              <meta
                name="description"
                content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities."
              />
              <meta
                name="classification"
                content="Salon chain with a wide range of offerings, from hair care to bridal makeup to nail care. Provides salon franchise options entrepreneurs."
              />
              <meta
                property="og:title"
                content="Smart Salon is an extensive chain of salons in India"
              />
              <meta
                property="og:image"
                content="https://smartsalon.in/SmartSalon/proplus"
              />
              <meta
                property="og:description"
                content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities."
              />
              <meta property="og:locale" content="en_IN" />
              <meta property="og:type" content="article" />
              <meta
                property="og:url"
                content="https://smartsalon.in/SmartSalon/proplus"
              />
              <meta
                property="og:site_name"
                content="Search Results |SmartSalon ProPlus"
              />
              <link
                rel="canonical"
                href="https://smartsalon.in/SmartSalon/proplus"
              />
              <meta
                name="robots"
                content="follow, index, all, noodp, noydir, max-snippet:-1, max-image-preview:large"
              />
              <meta name="revisit-after" content="2 days" />
              <meta name="googlebot" content="index,follow" />
              <meta name="expires" content="never" />
              <meta http-equiv="Content-Language" content="English" />
              <meta name="document-type" content="public" />
              <meta name="author" content="SmartSalon" />
              <script>{`
   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','GTM-T37QZ7DB');
 `}</script>

              {/* Google Tag Manager (noscript) */}
              <noscript>
              {`  <iframe
                  src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                ></iframe>`}
              </noscript>
            </Helmet>
          </>
        )}

        <div>
          <div>
            <div className=" h-[250px] sm:h-[350px] md:h-[500px]">
              <iframe
                width="100%"
                height="100%"
                src={fields[data]?.iframe?.src}
                title={fields[data]?.iframe?.src}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="flex gap-6 md:gap-0 flex-col md:flex-row w-[90%] md:w-[80%] mx-auto items-center justify-between my-9 md:my-16">
            <div className=" w-full md:w-[30%] bg-gray-100 flex items-center justify-center border shadow-md   py-9 px-3">
              <img src={fields[data]?.image} alt="logo icon" />
            </div>
            <div className="w-full md:w-[65%]">
              <p className="text-[#323232] text-[18px] ">
                {fields[data]?.mainText}
              </p>
            </div>
          </div>

          {contentArray?.map((elm, idx) => {
            const { title, items } = elm;
            return (
              <div
                key={idx}
                className="flex w-[90%] md:w-[80%] mx-auto my-6 items-center justify-center "
              >
                <ul className="w-full">
                  <p className="text-2xl my-3 w-full  text-[#323232] font-bold">
                    {" "}
                    {title}
                  </p>

                  {items.map((item, index) => (
                    <li
                      key={index}
                      className={`ml-0 text-balance md:ml-[32px] flex  ${
                        idx === contentArray?.length - 1 ? "my-2 gap-2 " : ""
                      } items-center  w-full`}
                    >
                      {idx === contentArray?.length - 1 && (
                        <FaCheckCircle
                          size={20}
                          className="text-green-500 w-[25px]"
                        />
                      )}

                      <p
                        className={`text-[#323232] text-pretty ${
                          idx === contentArray?.length - 1
                            ? "w-[90%] "
                            : "w-full"
                        } text-[18px] `}
                      >
                        {item}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}

          <div className="bg-new-yellow mt-9 px-3 py-16 flex flex-col justify-center items-center ">
            <p className="text-regel-gray font-medium text-2xl md:text-3xl">
              For More Queries Please Submit details in Franchise{" "}
            </p>
            <div className="mt-3">
              <button
                className="bg-black text-white py-3 px-6 font-medium"
                onClick={onpressFranchise}
              >
                Franchise Enqury
              </button>
            </div>
          </div>
        </div>
      </>

      <NewFooter />
    </>
  ) : (
    <Loader />
  );
}
