import ScrollAnimation from "react-animate-on-scroll";
import LOCAL_IMAGES from "../screens/utils/localImages";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleRedirection = () => {
    navigate("/franchise");
  };
  return (
    <div className="flex px-3 md:px-10 flex-col lg:flex-row  lg:gap-10 justify-between items-center w-full mt-4 lg:mt-20 mb-5 lg:mb-10 roboto-regular ">
     <div className=" w-full relative lg:w-1/2 py-2  mb-auto">
     <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
     <div className="w-full px-0 ">
     <div className="w-full relative  h-[350px]  sm:h-[500px] ">
        <img src={LOCAL_IMAGES.aboutus_icon} className="w-full relative z-0 h-full " alt="about us img" />
        <a href="#" className="absolute w-full h-full top-0 left-0 bg-black opacity-0 z-1 transition-opacity duration-300 hover:opacity-40 "></a>

      </div>
     </div>
     </ScrollAnimation>
     </div>
     
      <div className="w-full  lg:w-1/2 h-auto    mb-auto">
      <ScrollAnimation  animateOnce={true} animateIn="animate__fadeInDown">
        <h1 className="w-full  py-2 text-black text-start  font-extrabold text-xl  md:font-normal md:text-4xl opacity-80 roboto-regular ">
          Who Are We? Best Salon Franchise in India
        </h1>
        </ScrollAnimation>
        <ScrollAnimation delay={400} animateOnce={true} animateIn="animate__fadeIn">

        <p className="text-md sm:text-xl py-2 md:py-5   opacity-75">
          The Smart Salon is a nascent brand catering to sophisticated consumers
          seeking excellent talent with international expertise who are always
          improving and honing their craft. We are a company that will provide
          you with what you need via education, to upgrade skills of our artists
          to cater to our clients better each day. We have a very particular,
          clear-cut plan that will be carried out over the course of the next
          three to five years, with the main focus being on professional,
          in-depth training. In our initial phase, Monsoon will be the market
          leader with smart placement across top 77+ cities 05 Union Territories. We want to have
          over 200+ salons within the next two years, with a further 200 sites
          added in the final two years of our expansion. We have a very solid
          information structure in place to accommodate this kind of growth.
        </p>
        </ScrollAnimation>
        <button
          className=" bg-black text-white font-medium border   px-5 py-2 cursor-pointer"
          onClick={handleRedirection}
        >
          Know more
        </button>
      </div>
    </div>
  );
};

export default Header;
