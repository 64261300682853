import { useEffect, useState } from "react";
import LOCAL_IMAGES from "../utils/localImages";
import { ImLocation } from "react-icons/im";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SmallNavbar from "./SmallNavbar";
import "./index.css";
const Navbar = () => {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  
  const handleTabClick = (path) => {
    setActiveTab(path);
  };
  useEffect(() => {
    // Update isSmallScreen state on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1100);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 400); // Set isScrolled based on scroll position
      if (scrollTop > 400) {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);
  const handleFinder = () => {
    navigate("/salonfinder");
  };
  return (
    <>
      {isSmallScreen ? (
        <SmallNavbar />
      ) : (
        <>
          {/* <div className="flex bg-[#1a1a1a] justify-between py-1 px-9">
            <div>
              <Link to="/">
                <img
                  src={LOCAL_IMAGES.LOGOS}
                  className="w-24 h-12"
                  alt="logo"
                />
              </Link>
            </div>
            <div className=" text-[11px] font-medium text-white  flex justify-end items-center gap-x-6">
            <Link
              to="/bookAppointment"
              className={` uppercase underline underline-offset-2 decoration-red-600  ${
                activeTab === "/bookAppointment" ? "active" : ""
              }`}
              onClick={() => handleTabClick("/bookAppointment")}
            >
            
                Book Appointment
         
            </Link>
            <button
              className=" cursor-pointer   underline underline-offset-2 decoration-red-600 uppercase  text-white  flex items-center justify-center gap-x-2"
              onClick={handleFinder}
            >
            <p className="flex items-center">
              Salon Locator <ImLocation className=" h-fit" />
              </p>
            </button>
          
          </div>
          </div> */}
          
          <div
            className={` ${
              isScrolled ? "fixed-header z-[20] " : ""
            }  w-full bg-white  h-auto fixed top-0 z-[10] shadow-md border-b   roboto-medium-italic    flex justify-between items-center px-6 py-3  transition-all ease-in-out duration-500`}
          >
            <div>
              <Link to="/">
                <img
                  src={LOCAL_IMAGES.LOGOS}
                  className="w-32 h-16"
                  alt="logo"
                />
              </Link>
            </div>
            <div className='hidden md:flex justify-end items-center gap-x-6'>
      <Link to='/' className={`btn-hover ${activeTab === '/' ? 'active' : ''}`} onClick={() => handleTabClick('/')}>
        <h1 className={`text-new-gray font-bold link-text  cursor-pointer`}>Home</h1>
      </Link>
      <Link to='/franchise' className={`btn-hover ${activeTab === '/franchise' ? 'active' : ''}`} onClick={() => handleTabClick('/franchise')}>
        <h1 className={`text-new-gray font-bold link-text  cursor-pointer`}>Franchise</h1>
      </Link>
      <a href='https://prosaloncart.com' className='btn-hover'>
        <h1 className={`text-new-gray font-bold link-text  cursor-pointer`}>Eshop</h1>
      </a>
      <Link to='/gallery' className={`btn-hover ${activeTab === '/gallery' ? 'active' : ''}`} onClick={() => handleTabClick('/gallery')}>
        <h1 className={`text-new-gray font-bold link-text  cursor-pointer`}>Gallery</h1>
      </Link>
      <Link to='/media' className={`btn-hover ${activeTab === '/media' ? 'active' : ''}`} onClick={() => handleTabClick('/media')}>
        <h1 className={`text-new-gray font-bold link-text  cursor-pointer`}>Media</h1>
      </Link>
      <Link to='/about' className={`btn-hover ${activeTab === '/about' ? 'active' : ''}`} onClick={() => handleTabClick('/about')}>
        <h1 className={`text-new-gray font-bold link-text  cursor-pointer`}>About us</h1>
      </Link>
      <Link to='/bookAppointment' className={`btn-hover ${activeTab === '/bookAppointment' ? 'active' : ''}`} onClick={() => handleTabClick('/bookAppointment')}>
        <h1 className={`text-new-gray font-bold link-text  cursor-pointer`}>Book Appointment</h1>
      </Link>
      <button className='font-medium tracking-[0.05375rem] cursor-pointer uppercase bg-black text-white px-4 py-2  flex items-center gap-x-2' onClick={handleFinder}>
        Salon Finder <ImLocation />
      </button>
    </div>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;
