import React from 'react'
import LOCAL_IMAGES from '../utils/localImages'

// import './loader'

export default function Loader() {
  return (
    <div  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <img src={LOCAL_IMAGES.smartsalonGif} alt='loader'/>
    </div>
  )
}
